const NepaliDate = require('nepali-date');
const nd = new NepaliDate();
export default {
    editItem(data) {
    },

    store() {
        this.form.payment_mode = this.payment_mode_type;
        if ((!this.payment_mode_type.journal_type_id) || (!this.payment_mode_type.ledger_head_id)) {
            this.$events.fire('notification', { message: 'Data Validation failed', status: 'error' });
        } else if (this.form.voucher_no == '' && this.manualSetting) {
            this.$events.fire('notification', { message: 'Voucher No is required', status: 'error' });
        }
        else {
            this.form.general_type = 'payment';
            this.form.ledger = this.form.details;
            this.form.journal = this.payment_mode_type;
            // console.log(this.form.ledger[0]);
            if (!this.form.ledger[0].ledger_head_id || this.form.ledger[0].total_amount <= 0) {
                this.$events.fire('notification', {
                    message: 'Seems look like you have not entered Amount/Bank.',
                    status: 'error'
                });
            } else {
                if (this.form.entry_date.length === 8) {
                    this.form.entry_date = this.form.entry_date.substr(0, 4) + '-' + this.form.entry_date[4] + this.form.entry_date[5] + '-' + this.form.entry_date.slice(-2);
                }

                this.form.store().then(({ data }) => {
                    this.automaticVoucherNo = '';
                    this.payment_mode_type.journal_type_id = '';
                    this.payment_mode_type.ledger_head_id = '';
                    this.payment_mode_type.cheque_no = '';
                    this.payment_mode_type.bank_ledger_head_id = '';
                    this.payment_mode_type.final_amount = '';
                    this.payment_mode_type.mode = '';
                }).catch(e => {
                    this.$events.fire('notification', { message: e.message, status: 'error' });
                });
            }

        }
    },

    storeLedger() {
        // if(!this.ledgerForm.account_head_id) this.ledgerForm.account_head_id = this.ledgerForm.account_head_id.id;
        this.ledgerForm.store().then(({ data }) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({ data }) => {
            this.pr_type = data.data.filter(item => item.pr_type === 'payment').map(item => {
                return { value: item.id, text: item.name, setting: item.setting.voucher_numbering }
            });
        })
    },

    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({ data }) => {
            this.accountHeads = data.data.map(item => ({ value: item.id, text: item.name }));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({ data }) => {
            this.ledgerHeads = data.data.map(
                item => ({ value: item.id, text: item.name })
            );
            if (![undefined, null].includes(id)) {
                let len = this.form.details.length;
                if (len > 0) this.form.details[len - 1].ledger_head_id = id;
                else this.form.details[0].ledger_head_id = id;
            }
        })
    },

    addRow: function () {
        if ([undefined, null].includes(this.form.details)) {
            this.form.details = [{ ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0, opening_balance: '' }];
        } else {
            this.form.details.push({ ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0, opening_balance: '' });
        }
    },
    removeElement: function (row) {
        var index = this.form.details.indexOf(row);
        this.itemsLedger.splice(index, 1)
        this.form.details.splice(index, 1);
    },

    ledgerChange(data) {
        let head = this.ledgerHeads.find(item => item.value == data.ledger_head_id);
        data.ledger_head_name = head.text;
    },

    // getSerialNumber() {
    //     this.api.get('/payment-receipt/serial?type=payment').then(({data}) => {
    //         this.form.serial_no = data.serial;
    //     });
    // },

    lazySum(row) {
        return new Promise((resolve, reject) => {
            row.net_amount = parseFloat(row.total_amount) + parseFloat(row.tax);
            resolve(row);
        })
    },

    sumNetAmount(item) {
        if ([undefined, null].includes(item)) {
            if (typeof this.form.details === 'object' && this.form.details !== null) this.payment_mode_type.final_amount = this.form.details.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
        } else {
            this.lazySum(item).then(() => {
                if (isNaN(item.net_amount)) item.net_amount = 0
                this.payment_mode_type.final_amount = this.form.details.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
            })
        }
    },

    setEntryDate() {
        // this.form.entry_date = '2075-12-12'
        // if (this.form.entry_date == null) {
        //     let date = this.$converter.todayNP();
        //     this.form.entry_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        // }
    },

    paymentModeChange(d) {
        let pM = this.paymentMode.find(
            item => item.value == d
        );
        this.payment_mode_type.mode = pM.value;

        if (this.payment_mode_type.cheque_date == null) {
            let date = this.$converter.todayNP();
            this.payment_mode_type.cheque_date = nd.format('YY-MM-DD');
        }
        if (this.payment_mode_type.mode === 16) this.getBanks();
        else this.getCash();
    },

    getPayemntMode() {
        this.$rest.get('/api/ledger-head/cash-bank').then(({ data }) => {
            this.paymentMode = data.map(item => ({ value: item.id, text: item.name }));
        });
    },
    getBanks() {
        this.$rest.get('/api/ledger-head/banks?withOD=true').then(({ data }) => {
            this.banks = data.map(item => ({ value: item.id, text: item.name }));
        });
    },
    getCash() {
        this.$rest.get('/api/ledger-head/cash').then(({ data }) => {
            this.cashes = data.map(item => ({ value: item.id, text: item.name }));
        });
    },
    pickFile() {
        this.$refs.image.click()
    },
    onFilePicked(e) {
        this.form.files = e.target.files;
    },
    accountGroupSave() {
        this.accountGroupForm.parent_id = this.accountGroupForm.parent_id.id;
        this.accountGroupForm.store().then(({ data }) => {
            this.accountGroupFormDialog = false;
            this.entriesA = [data.data];
            this.ledgerForm.account_head_id = data.data.id

        });
    },
    ledgerHeadSearch(e) {
        if (this.searchKey !== null)
            this.$rest.get('/api/ledger-head?search=' + e.target.value)
                .then(({ data }) => {
                    this.itemsLedger[this.searchKey] = [];
                    data.data.map(res => {
                        this.itemsLedger[this.searchKey].push({ name: res.name, id: res.id });
                    });
                }).catch(err => {
                }).finally(() => (this.isLoading = false))
    },
    closingBalance(key) {
        this.$rest.get('/api/opening-balance?ledgerHeadId=' + this.form.details[key].ledger_head_id + '&date=' + this.form.entry_date + '&fiscalYearId=1').then(res => {
            this.form.details[key].opening_balance = res.data;
        });
    },
}
