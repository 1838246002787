import Form from '@/library/Form';
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

export default {
    form: new Form({
            general_type: '',
            narration: '',
            fiscal_year_id:'1',
            payment_mode: '',
            voucher_no:'',
            entry_date: nd.format('YYYY-MM-DD'),
            details: [{
                ledger_head_id: '',
                ledger_head_name: '',
                total_amount: 0,
                tax: 0,
                net_amount: 0,
                opening_balance: 0

            }],
            ledger:[],
            journal:{},
            files: ''
        },
        '/api/payment-receipt'
    ),
    accountGroupForm: new Form({
        name: '',
        description: '',
        company_id: '',
        parent_id: ''
    }, '/api/account-head'),
    accountGroupFormDialog: false,
    searchAG: null,


    searchA: null,
    entriesA: [],
    payment_mode_type: {
        mode: 'cash',
        cheque_no: '',
        cheque_date: '',
        journal_type_id: '',
        ledger_head_id: '',
        bank_ledger_head_id: '',
        cash_ledger_head_id: '',
        final_amount: 0
    },
    pr_type: [{value: '', text: ''}],

    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: '',
        opening_balance:0,
        drcr:'dr',
    }, '/api/ledger-head'),


    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/

    banks: [], /** Banks **/

    cashes : [], /**Cashes**/
    manualSetting : false,

    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],

    paymentMode: [],

    title: "Image Upload",
    dialog: false,
    imageName: '',
    imageUrl: '',
    imageFile: '',
    files: null,
    test: '',
    automaticVoucherNo : '',
    searchKey: null,
    itemsLedger: [],


}
